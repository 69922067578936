import React from 'react'
import { Container, Box } from 'theme-ui'
import ContentText from '@ui-components/ContentText'
import Reveal from '@ui-components/Reveal'
import Divider from '@ui-components/Divider'
import ContentImages from '@ui-components/ContentImages'
import ContentButtons from '@ui-components/ContentButtons'
import QuickSignupForm from '@ui-components/QuickSignupForm'
import WithDefaultContent from '@ui-blocks/WithDefaultContent'

const styles = {
  image: {
    // bg: `omegaLighter`,
    borderRadius: `default`,
    height: '50%',
    width: '72%',
    padding: '10px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: '2px 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }
}

const HeroBlock08 = ({ content: { text, buttons, form, images }, datas, locale }) => (
  <Container sx={{ textAlign: `center` }}>
    <Reveal effect='fadeInDown'>
      <Divider space={3} />
      <ContentText
        content={
          {
            "text": locale == 'en' ? datas.en_title : datas.fr_title,
            "variant": "h2",
            "color": "#521019",
          }
        }
      />
      <Box>
      <ContentText
          content={{
            text: locale === 'en' ? datas.en_category : datas.fr_category,
            variant: 'h4',
          }}
        />
      </Box>
    </Reveal>
    {form && (
      <>
        <Divider space={4} />
        <Reveal effect='fadeInRight' delay={0.7}>
          <QuickSignupForm {...form} space={3} />
        </Reveal>
      </>
    )}
    {images && (
      <>
        <Divider space={3} />
        <Box
          // sx={{ position: `relative` }}
          sx={styles.image}
        >
          <ContentImages
            content={{
              images: [
                {
                  "src": { publicURL: "https://crossjourneystorage.blob.core.windows.net/crossjourneycontainer/" + datas.detail_image, extension: "svg" },
                  "alt": "alt text",
                  "radius": "lg",
                  "shadow": "lg",
                  "effects": ["fadeInLeft"],
                  "borderRadius": '10px',
                }
              ]
            }}
            loading='eager'
            imagePosition='center'
            imageEffect='fadeInUp'
            sx={{ Height: '20rem', ...styles.image }}
          />
        </Box>
      </>
    )}
  </Container>
)

export default WithDefaultContent(HeroBlock08)
