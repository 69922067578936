import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@ui-layout/Layout'
import Seo from '@ui-components/Seo'
import Divider from '@ui-components/Divider'
import ModalWithTabs from '@ui-blocks/Modal/Block01'
import ModalSimple from '@ui-blocks/Modal/Block02'
import Header from '@ui-blocks/Header/Block01'
import Footer from '@ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes, converDate } from '@blocks-helpers'
import styles from './_styles'
import ContentOne from '@ui-blocks/Content/Block03'
import Hero from '@ui-blocks/Hero/Block08'
import { injectIntl } from 'gatsby-plugin-intl'
import ModalPrivacy from '@ui-blocks/Modal/Block03'
import Contact from '@ui-blocks/CallToAction/Block02'

const BlogView = props => {
  const params = new URLSearchParams(props.location.search);
  const id = parseInt(params.get("id"));
  const { locale } = props.intl

  const { allBlockContent } = props.data
  const [content, setContent] = useState(
    normalizeBlockContentNodes(allBlockContent?.nodes)
  )
  const [loder, setLoder] = useState(false)
  const [datas, setDatas] = useState([])

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.async = true;
    script.type = "text/javascript";
    script.setAttribute("id", "Cookiebot");
    script.setAttribute("data-cbid", "b4c6ff2f-3209-4e15-a3a7-1840c7fd5c02");
    script.setAttribute("data-blockingmode", "auto");
    document.body.appendChild(script);
  }, []);

  const getData = useCallback(() => {
    setLoder(true)
    fetch(`https://crossjourney.org/api/Page?name=pages/blogView&lang=${locale}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        converDate(data).then((datas) => {
          setContent(datas);
          // console.log('data', datas, normalizeBlockContentNodes(allBlockContent?.nodes));
        });
      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => setLoder(false))
  }, [locale])

  const getValues = useCallback(() => {
    setLoder(true)
    fetch(`https://crossjourney.org/api/Blog/` + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setDatas(data);
        // console.log('data', data);
      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => setLoder(false))
  }, [])

  useEffect(() => {
    getValues()
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues], [getData])

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalSimple content={content['search']} />
      <ModalPrivacy content={content['privacyNotice']} />
      <ModalPrivacy content={content['termsUse']} />
      {/* Blocks */}
      <Header content={content['header']} menuJustify='space-between' />
      <Divider space='5' />
      <Hero content={content['hero']} reverse datas={datas} locale={locale} />
      <Divider space='4' />
      <Container variant='narrow'>
        <ContentOne content={content['content-one']} datas={datas} locale={locale} />
      </Container>
      <Divider space='4' />
      <Contact content={content['cta']} />
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query pagesBlogViewBlockContent {
    allBlockContent(
      filter: { page: { in: ["pages/blogView", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default injectIntl(BlogView)
