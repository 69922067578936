import React from 'react'
import { Container, Flex, Box, css, Link } from 'theme-ui'
import Reveal from '@ui-components/Reveal'
import Divider from '@ui-components/Divider'
import ContentText from '@ui-components/ContentText'
import ContentButtons from '@ui-components/ContentButtons'
import WithDefaultContent from '@ui-blocks/WithDefaultContent'
import ContentImages from '@ui-components/ContentImages'
import { FaRegClock } from 'react-icons/fa'

const styles = {
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    minHeight: `15rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: [40, 132],
    bg: `omegaLighter`,
    borderRadius: `100% !important`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    },
    ml: [102, 0]
  }
}

const ContentBlock03 = ({ content: { collection }, reverse, datas, locale, text }) => (
  <Container>
    <Flex
      sx={{
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `2/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: ['center', 'left']
        }}
      >
        {collection?.[0] && (
          <Reveal effect='fadeInLeft'>
            <Box sx={{ textAlign: ['center', 'left'] }}>
              {/* <ContentText
                content={{
                  "text": locale === 'en' ? datas.en_authore : datas.fr_authore,
                  "color": "omegaDarker",
                  "variant": "h2",
                  "space": 3
                }}
                ml='0'
              /> */}
              {/* Footer */}
              <Box sx={{ variant: `text.small` }}>
                <Flex sx={styles.footerWrapper}>
                  <ContentImages
                    content={{
                      images: [
                        {
                          alt: "alt text",
                          "src": {
                            publicURL: "https://crossjourneystorage.blob.core.windows.net/crossjourneycontainer/" + datas.avatars,
                            extension: "png",
                            childImageSharp: {
                              gatsbyImageData: {
                                height: 132,
                                width: 132,
                                layout: "constrained",
                                images: {
                                  fallback: {
                                    src: "https://crossjourneystorage.blob.core.windows.net/crossjourneycontainer/" + datas.avatars
                                  }
                                }
                              }
                            }
                          }
                        }
                      ]
                    }}
                    sx={styles.avatar}
                    imageEffect='fadeIn'
                  />
                  <Flex sx={styles.postInfo}>
                    {/* Author */}
                    <ContentText
                      content={text?.[3]}
                      sx={{
                        display: `inline-block`,
                        flexBasis: `full`
                      }}
                    >
                      <Link color={text?.[3]?.color}>
                        <strong>{text?.[3]?.text}</strong>
                      </Link>
                    </ContentText>
                    {/* Info */}
                    <div sx={{ alignItems: `center` }}>
                      <div>
                        <ContentText
                          // content={text?.[4]}
                          content={{
                            "text": locale === 'en' ? datas.en_authore : datas.fr_authore,
                            "color": "omegaDark",
                            "variant": 'h6'
                          }}
                          sx={{ display: `inline-block` }}
                          mr='2'
                        >
                          {text?.[4]?.textGroup?.[0]}
                        </ContentText>
                      </div>
                      <div>
                        <ContentText
                          content={{
                            "text": locale === 'en' ? datas?.updated_date_en : datas?.updated_date_fr
                          }}
                          sx={{ display: `inline-block` }}
                          mr='2'
                        >
                        </ContentText>
                      </div>
                      <Box>
                        {<FaRegClock />}
                        <ContentText
                          content={{
                            "text": locale === 'en' ? datas.updated_time_en : datas.updated_time_fr
                          }}
                          sx={{ display: `inline-block` }}
                          ml='2'
                        />
                      </Box>
                    </div>
                  </Flex>
                </Flex>
              </Box>
            </Box>
            {collection[0]?.buttons && (
              <>
                <Divider space={3} />
                <Reveal
                  effect='fadeInRight'
                  delay={1}
                  css={css({ mb: [4, null, null, 0] })}
                >
                  <ContentButtons content={collection[0].buttons} />
                </Reveal>
              </>
            )}
          </Reveal>
        )}
      </Box>
      <Box
        sx={{
          flexBasis: `3/5`,
          alignSelf: `center`,
          textAlign: [`center`, null, null, `left`]
        }}
      >
        {collection?.[1] && (
          <Reveal effect='fadeInLeft'>
            <Box sx={{ textAlign: ['center', 'left'] }}>
              {/* <ContentText
                content={{
                  "text": locale === 'en' ? datas.en_title : datas.fr_title,
                  "color": "omegaDarker",
                  "variant": "h3"
                }}
                ml='0'
              /> */}
              <ContentText
                content={{
                  "text": locale === 'en' ? datas.en_short_description : datas.fr_short_description,
                  "color": "omegaDarker",
                  "variant": "h5"
                }}
                ml='0'
              />
            </Box>
            {collection[1]?.buttons && (
              <>
                <Divider space={3} />
                <Reveal
                  effect='fadeInRight'
                  delay={1}
                  css={css({ mb: [4, null, null, 0] })}
                >
                  <ContentButtons content={collection[1].buttons} />
                </Reveal>
              </>
            )}
          </Reveal>
        )}
      </Box>
    </Flex>
    <Box>
      <ContentText
        content={{
          "text": locale === 'en' ? datas.en_long_description : datas.fr_long_description,
          "color": "omegaDark"
        }}
        ml='0'
      />
    </Box>
  </Container>
)

export default WithDefaultContent(ContentBlock03)
